import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { CONTENT_METADATA_URL, SAVE_CONTENT_METADATA_URL } from '../../utils/Constants';
import { formatDateToLastUpdated, getJwtToken } from '../utils';
import { save_questions } from './questions';
import { save_chapters } from './chapters';

/* How to inject the authorization header once for all request? */
axios.interceptors.request.use(
  async function (config) {
    const jwtToken = await getJwtToken();
    config.headers = { Authorization: jwtToken };
    config.params = { filterFields: false };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const subscriptionsList = [
  {
    displayName: 'BMJ Learning standard',
    id: '1',
    code: 'bmj-learning-standard'
  },
  {
    displayName: 'Free',
    id: '2',
    code: 'bmj-learning-free'
  }
];
export const additionalSubscriptionsList = [
  // Journal
  { displayName: 'BMJ Learning JMG', id: '31', code: 'bmj-learning-highwire-jmg' },
  { displayName: 'BMJ Learning Journal', id: '23', code: 'bmj-learning-journal' },
  { displayName: 'BMJ Learning Quality', id: '22', code: 'bmj-learning-quality' },
  {
    displayName: 'British Journal of Sports Medicine',
    id: '11',
    code: 'bmj-learning-highwire-bjsm'
  },
  { displayName: 'Education in Heart', id: '7', code: 'bmj-learning-highwire-education-in-heart' },
  { displayName: 'Gut', id: '8', code: 'bmj-learning-highwire-gut' },
  { displayName: 'Education in heart', id: '9', code: 'bmj-learning-highwire-heart' },
  { displayName: 'Journal of Clinical Pathology', id: '10', code: 'bmj-learning-highwire-jcp' },
  // India course
  { displayName: 'BMJ Learning CKD India 1', id: '60', code: 'bmj-learning-ckd-india-1' },
  { displayName: 'BMJ Learning CKD India 2', id: '61', code: 'bmj-learning-ckd-india-2' },
  { displayName: 'BMJ Learning CKD India 3', id: '62', code: 'bmj-learning-ckd-india-3' },
  { displayName: 'BMJ Learning CKD India 4', id: '63', code: 'bmj-learning-ckd-india-4' },
  { displayName: 'BMJ Learning CKD India 5', id: '64', code: 'bmj-learning-ckd-india-5' },
  { displayName: 'BMJ Learning CKD India 6', id: '65', code: 'bmj-learning-ckd-india-6' },
  { displayName: 'BMJ Learning CKD India 7', id: '66', code: 'bmj-learning-ckd-india-7' },
  { displayName: 'BMJ Learning CKD India 8', id: '67', code: 'bmj-learning-ckd-india-8' },
  { displayName: 'BMJ Learning CKD India 9', id: '68', code: 'bmj-learning-ckd-india-9' },
  { displayName: 'BMJ Learning CKD India 10', id: '69', code: 'bmj-learning-ckd-india-10' },
  { displayName: 'BMJ Learning CKD India 11', id: '70', code: 'bmj-learning-ckd-india-11' },
  { displayName: 'BMJ Learning CKD India 12', id: '71', code: 'bmj-learning-ckd-india-12' },
  { displayName: 'BMJ Learning CKD India 13', id: '72', code: 'bmj-learning-ckd-india-13' },
  { displayName: 'BMJ Learning diabetes 1', id: '41', code: 'bmj-india-diabetes-unit-1' },
  { displayName: 'BMJ Learning diabetes 2', id: '42', code: 'bmj-india-diabetes-unit-2' },
  { displayName: 'BMJ Learning diabetes 3', id: '43', code: 'bmj-india-diabetes-unit-3' },
  { displayName: 'BMJ Learning diabetes 4', id: '44', code: 'bmj-india-diabetes-unit-4' },
  { displayName: 'BMJ Learning diabetes 5', id: '45', code: 'bmj-india-diabetes-unit-5' },
  { displayName: 'BMJ Learning diabetes 6', id: '46', code: 'bmj-india-diabetes-unit-6' },
  { displayName: 'BMJ Learning India diabetes 7', id: '89', code: 'bmj-india-diabetes-unit-7' },
  {
    displayName: 'BMJ Learning India Palliative Care 1',
    id: '75',
    code: 'bmj-learning-palliative-india-1'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 2',
    id: '76',
    code: 'bmj-learning-palliative-india-2'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 3',
    id: '77',
    code: 'bmj-learning-palliative-india-3'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 4',
    id: '78',
    code: 'bmj-learning-palliative-india-4'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 5',
    id: '79',
    code: 'bmj-learning-palliative-india-5'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 6',
    id: '80',
    code: 'bmj-learning-palliative-india-6'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 7',
    id: '81',
    code: 'bmj-learning-palliative-india-7'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 8',
    id: '82',
    code: 'bmj-learning-palliative-india-8'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 9',
    id: '83',
    code: 'bmj-learning-palliative-india-9'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 10',
    id: '84',
    code: 'bmj-learning-palliative-india-10'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 11',
    id: '85',
    code: 'bmj-learning-palliative-india-11'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 12',
    id: '86',
    code: 'bmj-learning-palliative-india-12'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 13',
    id: '87',
    code: 'bmj-learning-palliative-india-13'
  },
  {
    displayName: 'BMJ Learning India Palliative Care 14',
    id: '88',
    code: 'bmj-learning-palliative-india-14'
  },
  {
    displayName: 'BMJ Learning: Rheumatology Unit 1',
    id: '99',
    code: 'bmj-learning-rheum-india-1'
  },
  {
    displayName: 'BMJ Learning: Rheumatology Unit 2',
    id: '100',
    code: 'bmj-learning-rheum-india-2'
  },
  {
    displayName: 'BMJ Learning: Rheumatology Unit 3',
    id: '101',
    code: 'bmj-learning-rheum-india-3'
  },
  {
    displayName: 'BMJ Learning: Rheumatology Unit 4',
    id: '102',
    code: 'bmj-learning-rheum-india-4'
  },
  {
    displayName: 'BMJ Learning: Rheumatology Unit 5',
    id: '103',
    code: 'bmj-learning-rheum-india-5'
  },
  {
    displayName: 'BMJ Learning Rheumatology Unit 6',
    id: '104',
    code: 'bmj-learning-rheum-india-6'
  },
  // Customer
  { displayName: 'BMJ Learning: ADB', id: '95', code: 'bp-learning-adb' },
  { displayName: 'BMJ Learning Darwin', id: '56', code: 'bmj-learning-dtra' },
  { displayName: 'BMJ Learning Elucidat Test', id: '57', code: 'bmj-learning-elucidat-test' },
  { displayName: 'BMJ Learning - RSM study day modules', id: '2', code: 'bmj-learning-rsm' },
  // Collection/topic
  {
    displayName: 'BMJ Learning Basic Life Support',
    id: '25',
    code: 'bmj-learning-basic-life-support'
  },
  {
    displayName: 'Learning: Common respiratory conditions',
    id: '94',
    code: 'learning-common-respiratory-conditions'
  },
  { displayName: 'BMJ Learning ECG Skills', id: '29', code: 'bmj-learning-ecg-skills' },
  { displayName: 'BMJ Learning Surgical Skills', id: '17', code: 'bmj-learning-surgical-skills' },
  // China
  { displayName: 'BMJL Chinese modules', id: '38', code: 'bmj-learning-chinese-modules' },
  // BMA
  { displayName: 'BMA Membership', id: '3', code: 'bmj-learning-bma-membership' },
  { displayName: 'BMA reps', id: '4', code: 'bmj-learning-bma-reps' },
  { displayName: 'BMA Staff', id: '27', code: 'bmj-learning-bma-staff' },
  { displayName: 'BMJ Learning: BMA PCN', id: '93', code: 'bmj-learning-bma-pcn' },
  { displayName: 'Imperial Research Institution', id: '98', code: 'imperial-research-institution' },
  // Rtop
  { displayName: 'Research to Publication', id: '36', code: 'bmj-learning-r2p' }
];
const emptyCourse = () => ({
  objectives: [],
  title: '',
  dateUpdated: formatDateToLastUpdated(),
  accessResources: [],
  duration: {},
  contributors: [],
  doi: '',
  targetAudiences: [],
  homepageImage: '',
  category: [],
  partnersLogo: [],
  description: '',
  facultyDisclosure: '',
  locale: 'en_GB',
  type: 'COURSE',
  moduleType: 'EL2',
  status: 'PEND'
});

const initialState = {
  data: emptyCourse(),
  status: 'idle',
  err: false,
  serverError: false,
  errMsg: '',
  subscriptionsList,
  additionalSubscriptionsList,
  additionalSubscriptions: []
};

export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    SET_COURSE(state, { payload }) {
      state.data = payload;
    },
    HANDLE_NEW_COURSE(state) {
      state.data = emptyCourse();
      state.data.contributors = [];
    },
    UPDATE_COURSE(state, { payload: { key, value } }) {
      state.data[key] = value;
    },
    HANDLE_STATUS(state, { payload }) {
      state.status = payload;
    },
    SET_COURSE_PROP(state, { payload: { key, value } }) {
      state.data[key] = value;
    },
    UPDATE_SUBSCRIPTIONS(state, { payload: item }) {
      const subscriptionCopy = [...state.data.accessResources];
      const index = subscriptionCopy.findIndex((obj) => +obj.id === +item.id);
      if (index === -1) {
        const i = subscriptionsList.findIndex((o) => +o.id === +item.id);
        subscriptionCopy.push(subscriptionsList[i]);
      } else {
        subscriptionCopy.splice(index, 1);
      }
      state.data.accessResources = subscriptionCopy;
    },
    UPDATE_ADDITIONAL_SUBSCRIPTION(state, { payload = [] }) {
      const array = state.data.accessResources.filter((obj) => +obj.id < 3);
      payload.forEach((itemInPayload) => {
        array.push(itemInPayload);
      });
      state.data.accessResources = array;
    },
    HANDLE_CHIP_SELECTION(state, { payload: { section, id, action, list } }) {
      let chipIndex;
      switch (action) {
        case 'selectAll':
          state.data[section] = [...list];
          break;
        case 'deselectAll':
          state.data[section] = [];
          break;
        case 'single':
          chipIndex = state.data[section].findIndex((obj) => +obj.id === +id);
          if (chipIndex === -1) {
            const index = list.findIndex((obj) => +obj.id === +id);
            const chip = list[index];
            state.data[section].push(chip);
          } else {
            state.data[section].splice(chipIndex, 1);
          }
          break;
        default:
          break;
      }
    },
    HANDLE_AUTHOR(state, { payload: { key, value, index } }) {
      const authors = [...state.data.contributors];
      authors[index][key] = value;
      state.data.contributors = authors;
    },
    HANDLE_ADD_DELETE_AUTHOR(state, { payload }) {
      let data;
      if (payload === 'add') {
        const newAuthor = {
          name: '',
          type: 'original-author',
          description: '',
          disclosure: ''
        };
        data = [...state.data.contributors, newAuthor];
        state.data.contributors = data;
      } else {
        data = [...state.data.contributors];
        data.pop();
        state.data.contributors = data;
      }
      UPDATE_COURSE(state, { payload: { key: 'contributors', value: state.data.contributors } });
    },
    HANDLE_SERVER_ERR(state, { payload }) {
      state.serverError = payload;
    }
  }
});

export const {
  SET_COURSE,
  HANDLE_NEW_COURSE,
  UPDATE_COURSE,
  UPDATE_SUBSCRIPTIONS,
  UPDATE_ADDITIONAL_SUBSCRIPTION,
  HANDLE_CHIP_SELECTION,
  HANDLE_AUTHOR,
  HANDLE_ADD_DELETE_AUTHOR,
  HANDLE_STATUS,
  HANDLE_SERVER_ERR
} = courseSlice.actions;

export const get_course = ({ id, language }) =>
  async function (dispatch) {
    try {
      dispatch(HANDLE_STATUS('loading'));
      const url = CONTENT_METADATA_URL.replace('{id}', id).replace('{language}', language);
      const { data } = await axios.get(url);
      // if we don't receive an array of authors, we need to add an empty array
      // to avoid data type errors
      !data.contributors && (data.contributors = []);
      const parsedData = JSON.stringify(data);
      dispatch(SET_COURSE(data));
      dispatch(HANDLE_STATUS('idle'));
      return JSON.parse(parsedData);
    } catch (e) {
      dispatch(HANDLE_STATUS('error'));
    }
  };

export const save_course = () =>
  async function (dispatch, getState) {
    try {
      const {
        course: { data }
      } = getState();
      const course = { ...data };
      (!course.partnersLogo || !course.partnersLogo.length) && delete course.partnersLogo;
      delete course.isRtop;
      // course.accessResources = setAccessResources(course, additionalSubscriptions);
      dispatch(HANDLE_STATUS('saving'));
      await axios.put(`${SAVE_CONTENT_METADATA_URL}/course`, course);
      dispatch(HANDLE_STATUS('success'));
      setTimeout(() => {
        dispatch(HANDLE_STATUS('idle'));
      }, 3000);
    } catch (err) {
      dispatch(HANDLE_STATUS('error'));
      dispatch(HANDLE_SERVER_ERR(true));
    }
  };

export const create_new_course = ({ language = 'en_GB' }) =>
  async function (dispatch, getState) {
    try {
      const {
        course: { data }
      } = getState();
      // course.accessResources = setAccessResources(course, additionalSubscriptions);
      dispatch(HANDLE_STATUS('saving'));
      const response = await axios.post(`${SAVE_CONTENT_METADATA_URL}/course`, data);
      dispatch(SET_COURSE(response.data));
      dispatch(HANDLE_STATUS('success'));
      if (data.moduleType === 'LEP') {
        dispatch(save_questions({ id: response.data.id, language })),
        dispatch(save_chapters({ id: response.data.id, language }));
      }
      if (data.moduleType === 'MCQ') {
        dispatch(save_questions({ id: response.data.id, language }));
      }
      setTimeout(() => {
        dispatch(HANDLE_STATUS('idle'));
      }, 3000);
      // replace in the browser address url the word "new" with the id of the newly created course
      window.history.pushState(null, null, `/course/${response.data.id}`);
    } catch (e) {
      dispatch(HANDLE_STATUS('error'));
      dispatch(HANDLE_SERVER_ERR(true));
    }
  };

export default courseSlice.reducer;
