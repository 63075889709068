import {
  BaseModal,
  ConfirmationPopup,
  CourseInformation,
  CourseKeyDetails,
  CourseLearningObjectives,
  CourseAuthors,
  ChaptersManagement,
  CourseQuestions,
  BaseSideMenu
} from '../components';
import linkIcon from '../assets/link-external.svg';
import { useParams, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  create_new_course,
  get_course,
  save_course,
  HANDLE_STATUS,
  UPDATE_COURSE,
  HANDLE_NEW_COURSE
} from '../store/modules/course';
import { CLEAR_QUESTIONS, save_questions, get_questions } from '../store/modules/questions';
import { fetch_chapters, SET_CHAPTERS, save_chapters } from '../store/modules/chapters';
import './css/module-edit.scss';
import '../components/css/content-editor.scss';

export const quickLinksData = {
  title: 'Quick Links',
  options: ['Key details', 'Course information', 'Learning objectives', 'Authors']
};
export const actions = {
  title: 'Course actions',
  options: [
    {
      name: 'Create a new course',
      action: 'create',
      cy: 'create-new-course'
    },
    {
      name: 'Update last updated date',
      action: 'update_date'
    },
    {
      name: 'Open live site',
      icon: linkIcon,
      action: 'open_live_site'
    }
  ]
};

const CourseEdit = function () {
  const { id, language = 'en_GB' } = useParams();
  const {
    location: { pathname }
  } = useHistory();
  const [quickLinks, setQuickLinks] = useState(quickLinksData);
  const dispatch = useDispatch();
  const { id: courseId, moduleType } = useSelector((state) => state.course.data);
  const { serverError, status } = useSelector((state) => state.course);

  useEffect(() => {
    let array;
    switch (moduleType) {
      case 'LEP':
        courseId && dispatch(fetch_chapters({ id, language }));
        courseId && dispatch(get_questions({ id, language }));
        array = [...quickLinks.options.filter((val, index) => index < 4), 'Chapters', 'Questions'];
        break;
      case 'MCQ':
        array = [...quickLinks.options.filter((val, index) => index < 4), 'Questions'];
        break;
      default:
        array = quickLinks.options.filter((val, index) => index < 4);
    }
    setQuickLinks((prev) => ({
      ...prev,
      options: array
    }));
  }, [moduleType]);

  useEffect(() => {
    // this one should trigger if the course is new
    // if the route contains /new then trigger this
    if (window.location.pathname.includes('/new')) {
      dispatch(HANDLE_NEW_COURSE());
      dispatch(SET_CHAPTERS([]));
      dispatch(CLEAR_QUESTIONS());
    } else {
      dispatch(get_course({ id, language }));
    }
  }, []);

  function handleMenuAction(action) {
    switch (action) {
      case 'create':
        dispatch(HANDLE_NEW_COURSE());
        dispatch(SET_CHAPTERS([]));
        dispatch(CLEAR_QUESTIONS());
        window.history.replaceState({}, '', '/course/new');
        break;
      case 'update_date':
        dispatch(UPDATE_COURSE({ key: 'dateUpdated', value: new Date() }));
        break;
      case 'open_live_site':
        window.open(`https://new-learning.bmj.com${pathname}`, '_blank');
        break;
    }
  }

  function clearServerErr() {
    dispatch(HANDLE_STATUS({ status: 'idle', serverError: false }));
  }

  async function handleSaveAll() {
    if (courseId) {
      dispatch(save_course());
      if (moduleType === 'LEP') {
        dispatch(save_questions({ id: courseId, language })),
          dispatch(save_chapters({ id: courseId, language }));
      }
      if (moduleType === 'MCQ') {
        dispatch(save_questions({ id: courseId, language }));
      }
    }
    if (!courseId) {
      dispatch(create_new_course({ language }));
    }
  }

  return (
    <main className="module-edit content-editor">
      {serverError && (
        <BaseModal>
          <ConfirmationPopup handleConfirm={clearServerErr} />
        </BaseModal>
      )}

      <BaseSideMenu
        quickLinks={quickLinks}
        status={status}
        actions={actions}
        handleSave={handleSaveAll}
        handleAction={handleMenuAction}
      />

      <CourseKeyDetails />

      <CourseInformation />

      <CourseLearningObjectives id="learning-objectives" />

      <CourseAuthors />

      {moduleType === 'LEP' && <ChaptersManagement />}

      {(moduleType === 'LEP' || moduleType === 'MCQ') && (
        <CourseQuestions />
      )}
    </main>
  );
};

export default CourseEdit;
