import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  Grid,
  CircularProgress,
  Button
} from '@material-ui/core';
import '../../App.scss';
import './batch-accreditation.scss';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import '../../App.scss';
import {
  ACCREDITATIONS_URL,
  CREATE_MULTIPLE_ACCREDITED_RESOURCES_URL
} from '../../utils/Constants';
import { fetchBatchMetadata } from '../../store/api';

const SUBMIT_SUCCESS_MSG = 'Successful';
const SUBMIT_FAILURE_MSG = 'Failed';

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ModuleId'
  },
  { id: 'title', numeric: true, disablePadding: true, label: 'Module title' }
];

const headCellsAccreditations = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Organisation name'
  },
  { id: 'minutes', numeric: true, disablePadding: false, label: 'Minutes' },
  { id: 'points', numeric: true, disablePadding: false, label: 'Points' },
  {
    id: 'statement',
    numeric: true,
    disablePadding: false,
    label: 'Accreditation statement'
  }
];

const EnhancedTableHead = ({ onSelectAllClick, numSelected, rowCount, headCells }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'default'}
            className="padding-left-zero"
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = ({ numSelected, title }) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {title}
        </Typography>
      )}
    </Toolbar>
  );
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: '#fff'
        }
      : {
          color: '#fff'
        },
  title: {
    flex: '1 1 100%'
  }
}));

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  table: {
    minWidth: 500,
    padding: '6px 24px 6px 0px'
  },
  moduleid: {
    width: 50
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

const CourseAccreditations = () => {
  const classes = useStyles();
  const [selectedModules, setSelectedModule] = React.useState([]);
  const [accreditations, setAccreditations] = useState(null);
  const [selectedAccreditations, setSelectedAccreditations] = React.useState([]);
  const [result, setResult] = useState();
  const handleSelectAllClickModule = (event) => {
    if (event.target.checked) {
      const selectedModules = modules.map((module) => module.id);
      setSelectedModule(selectedModules);
      return;
    }
    setSelectedModule([]);
  };

  const handleClickModules = (event, name) => {
    const selectedIndex = selectedModules.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedModules, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedModules.slice(1));
    } else if (selectedIndex === selectedModules.length - 1) {
      newSelected = newSelected.concat(selectedModules.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedModules.slice(0, selectedIndex),
        selectedModules.slice(selectedIndex + 1)
      );
    }

    setSelectedModule(newSelected);
  };

  const isSelectedModules = (id) => selectedModules.indexOf(id) !== -1;

  useEffect(() => {
    fetch(ACCREDITATIONS_URL)
      .then((response) => response.json())
      .then((json) => setAccreditations(json))
      .catch((err) => console.error(err));
  }, []);

  const [modules, setModules] = useState(null);

  useEffect(() => {
      fetchBatchMetadata('course', 'en-gb')
      .then((data) => data.filter((course) => course.status === 'LIVE'))
      .then((moduleList) => setModules(moduleList));
  }, []);

  const handleSelectAllClickAccreditation = (event) => {
    if (event.target.checked) {
      const selectedAccreditations = accreditationsToDisplay.map((n) => n.id);
      setSelectedAccreditations(selectedAccreditations);
      return;
    }
    setSelectedAccreditations([]);
  };

  const addLeadingZeroToDigit = (num) => {
    return num < 10 ? '0' + num : num;
  };

  const buildAccreditedResourcesObject = () => {
    let currentDateTime = new Date();
    const dd = addLeadingZeroToDigit(currentDateTime.getDate());
    const mm = addLeadingZeroToDigit(currentDateTime.getMonth() + 1);
    const yyyyFrom = currentDateTime.getFullYear();
    const yyyyTo = yyyyFrom + 15;

    const fromDate = yyyyFrom + '-' + mm + '-' + dd;
    const toDate = yyyyTo + '-' + mm + '-' + dd;

    const selectedAccreditationsToBeSaved = accreditations.filter((acc) =>
      selectedAccreditations.includes(acc.id)
    );

    return selectedModules.flatMap((moduleId) =>
      selectedAccreditationsToBeSaved.map((accreditation) => ({
        accreditationId: accreditation.id,
        moduleId: moduleId,
        validFrom: fromDate,
        validTo: toDate,
        language: 'en-gb'
      }))
    );
  };

  const handleSubmitButtonClick = () => {
    const listOfCreateAccreditedResources = buildAccreditedResourcesObject();
    postAccreditedResources(listOfCreateAccreditedResources);
  };

  const postAccreditedResources = (data) => {
    fetch(CREATE_MULTIPLE_ACCREDITED_RESOURCES_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((response) => {
        if (response.ok) {
          setResult(SUBMIT_SUCCESS_MSG);
        } else {
          setResult(SUBMIT_FAILURE_MSG);
        }
      })
      .catch(() => {
        setResult(SUBMIT_FAILURE_MSG);
      });
  };

  const handleClickAccreditations = (event, id) => {
    const selectedIndex = selectedAccreditations.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedAccreditations, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedAccreditations.slice(1));
    } else if (selectedIndex === selectedAccreditations.length - 1) {
      newSelected = newSelected.concat(selectedAccreditations.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedAccreditations.slice(0, selectedIndex),
        selectedAccreditations.slice(selectedIndex + 1)
      );
    }
    setSelectedAccreditations(newSelected);
  };

  const isSelectedAccreditations = (id) => selectedAccreditations.indexOf(id) !== -1;

  const [filterByCpdMinutesKeyword, setFilterByCpdMinutesKeyword] = useState('');
  const accreditationsToDisplay =
    filterByCpdMinutesKeyword !== ''
      ? accreditations.filter(
          (accreditation) => accreditation.cpdMinutes.toString() === filterByCpdMinutesKeyword
        )
      : accreditations;

  const handleFilterByCpdMinutes = (e) => {
    setFilterByCpdMinutesKeyword(e.target.value);
  };
  return (
    <main className="batch-accreditation">
      <Grid container className="batch-acc">
        <Grid container>
          <Typography variant="h2" component="h2">
            Batch Accreditation
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={6} className="filter">
            <h3>Filter by cpd minutes</h3>
            <input onChange={handleFilterByCpdMinutes} type="number" />
          </Grid>
        </Grid>
        <Grid className={classes.root} md="6">
          <Paper className="table-container">
            <EnhancedTableToolbar numSelected={selectedModules.length} title="Modules" />
            <TableContainer style={{ maxHeight: 550 }}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  numSelected={selectedModules.length}
                  onSelectAllClick={handleSelectAllClickModule}
                  rowCount={modules && modules.length}
                  headCells={headCells}
                />
                <TableBody>
                  {modules &&
                    modules.map((module, index) => {
                      const isItemSelected = isSelectedModules(module.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={module.name}
                          selected={isItemSelected}
                          onClick={(event) => handleClickModules(event, module.id)}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {module.id}
                          </TableCell>
                          <TableCell align="left">{module.title}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {!modules && (
            <div class="loadingModules">
              <CircularProgress color="inherit" thickness={3} size={25} />
            </div>
          )}
        </Grid>
        <Grid md="6">
          <Paper className="table-container2">
            <EnhancedTableToolbar
              numSelected={selectedAccreditations.length}
              title="Accreditations"
            />
            <TableContainer style={{ maxHeight: 550 }}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selectedAccreditations.length}
                  onSelectAllClick={handleSelectAllClickAccreditation}
                  rowCount={accreditationsToDisplay && accreditationsToDisplay.length}
                  headCells={headCellsAccreditations}
                />
                <TableBody>
                  {accreditationsToDisplay &&
                    accreditationsToDisplay.map((accreditation, index) => {
                      const isItemSelected = isSelectedAccreditations(accreditation.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={accreditation.id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                              onClick={(event) =>
                                handleClickAccreditations(event, accreditation.id)
                              }
                            />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {accreditation.organisation.name}
                          </TableCell>
                          <TableCell>{accreditation.cpdMinutes}</TableCell>
                          <TableCell>{accreditation.cpdPoints.toFixed(2)}</TableCell>
                          <TableCell align="left">{accreditation.accreditationStatement}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {!accreditations && (
            <div class="loading">
              <CircularProgress color="inherit" thickness={3} size={25} />
            </div>
          )}
        </Grid>
        <Grid container>
          <div className="result">
            <Button
              variant="contained"
              className="primary-button"
              onClick={() => handleSubmitButtonClick()}
              disabled={selectedModules.length === 0 || selectedAccreditations.length === 0}
            >
              Apply accreditations
            </Button>
            <Typography variant="h3" component="h3">
              {result}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </main>
  );
};
export default CourseAccreditations;
