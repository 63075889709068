import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { REVIEWS_URL } from '../../utils/Constants';
import { extractIds, mapStatus } from '../../utils/reviewsUtils';

const initialState = {
  totalReviewsCount: '',
  pageNumber: 1,
  pageSize: 100,
  pagedReviews: [],
  reviews: [],
  selected: [],
  updateStatus: '',
  filter: {
    applied: false,
    dateFrom: '',
    dateTo: '',
    status: 'ALL'
  },
  editorComment: {
    id: '',
    review: '',
    comment: ''
  },
  status: 'idle'
};

export const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    SET_STATE (state, { payload: { key, value } }) {
      state[key] = value;
    },
    TOGGLE_SELECTED (state, { payload: id }) {
      const i = state.selected.findIndex((val) => val === id);
      i  > -1
        ? state.selected.splice(i, 1)
        : state.selected.push(id);
    },
    UPDATE_STATUS (state, { payload: { selected, status } }) {
      selected.forEach((id) => {
        const i = state.reviews.findIndex((val) => val.id === id);
        state.reviews[i].status = status;
      });
    },
    UPDATE_COMMENT (state, { payload: { id, comment } }) {
      const i = state.reviews.findIndex((val) => val.id === id);
      state.reviews[i].comment = comment;
    },
    RESET_SELECTION (state) {
      state.selected = [];
    },
    SET_FILTER_DEFAULT_DATES (
      state,
      { payload: { dateFrom, dateTo } }
    ) {
      state.filter.dateFrom = dateFrom;
      state.filter.dateTo = dateTo;
    }
  }
});

export const {
  SET_STATE,
  TOGGLE_SELECTED,
  UPDATE_STATUS,
  RESET_SELECTION,
  UPDATE_COMMENT,
  SET_FILTER_DEFAULT_DATES
} = reviewsSlice.actions;

// export const handlePagination = (newPage, currReviews) =>
//   async function (dispatch, getState) {
//     const {
//       reviews: { pageSize, reviews }
//     } = getState();
//     console.log('new PAge here', newPage);
//     dispatch(
//       SET_STATE({
//         key: 'pageNumber',
//         value: newPage
//       })
//     );
    
//     let startIndex = (newPage - 1) * pageSize;
//     let endIndex = (newPage * pageSize) > reviews.length ? reviews.length : (newPage * pageSize);
//     let teRev = currReviews.length > 0 ? currReviews.slice(startIndex , endIndex) : reviews.slice(startIndex , endIndex);
//     console.log('paged review here ', teRev);
  
//     dispatch(SET_STATE({
//       key: 'pagedReviews',
//       value: currReviews.length > 0 ? currReviews.slice(startIndex , endIndex) : reviews.slice(startIndex , endIndex)
//     }));
//   }

export const fetch_reviews = (pageNumber, pageSize) =>
  async function (dispatch) {
    dispatch(SET_STATE({
      key: 'status',
      value: 'loading'
    }));
    try {
      const url = `${REVIEWS_URL}?page=0&size=1000`;
      const { data } = await axios.get(url);
      await dispatch(SET_STATE({
        key: 'reviews',
        value: data
      }));
      
      await dispatch(SET_STATE({
        key: 'totalReviewsCount',
        value: data.length
      }));
      
      let startIndex = (pageNumber - 1) * pageSize;
      let endIndex = (pageNumber * pageSize) > data.length ? data.length : (pageNumber * pageSize);

      dispatch(SET_STATE({
        key: 'pagedReviews',
        value: data.slice(startIndex , endIndex)
      }));
      // handlePagination(pageNumber - 1);
      console.log('data here for 1000', data);
      // Change 'UNREVIEWED' status to 'UNPUBLISHED' for UI
      dispatch(UPDATE_STATUS({
        selected: mapStatus(data),
        status: 'UNPUBLISHED'
      }));
      dispatch(
        SET_FILTER_DEFAULT_DATES({
          dateFrom: data[0].dateAdded,
          dateTo: data[0].dateAdded
        })
      );
      dispatch(SET_STATE({
        key: 'status',
        value: 'success'
      }));
    } catch(err) {
      console.log('err:', err);
      dispatch(SET_STATE({
        key: 'status',
        value: 'error'
      }));
    }
  };

  export const update_review_status = (payload, status) =>
    async function (dispatch) {
      try {
        const { data } = await axios.put(REVIEWS_URL, payload);
        dispatch(UPDATE_STATUS({
          selected: extractIds(data),
          status: status
        }));
      } catch (err) {
        console.log('err:', err);
        dispatch(SET_STATE({
          key: 'status',
          value: 'error'
        }));
      }
    }

    export const update_review_comment = (payload) =>
      async function (dispatch) {
        try {
          const { data } = await axios.put(REVIEWS_URL, payload);
          dispatch(UPDATE_COMMENT({
            id: data[0].id,
            comment: data[0].comment
          }));
        } catch (err) {
          console.log('err:', err);
          dispatch(SET_STATE({
            key: 'status',
            value: 'error'
          }));
        }
      }

export default reviewsSlice.reducer;