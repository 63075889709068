import { BaseTabsMenu, BaseInput, BaseTextarea, BaseAddNewButton, BaseDeleteButton } from './index';
import { useEffect, useState } from 'react';
import './css/course-authors.scss';

export const CollectionAuthors = function ({
  authorsList = [],
  handleAddDeleteAuthor,
  handleAuthorUpdate
}) {
  const [authors, setAuthors] = useState([]);
  const options = ['Original author', 'Update author', 'Peer reviewer', 'Contributor'];

  useEffect(() => {
    const filtered = authorsList.map((obj) => {
      return {
        ...obj,
        type: obj.type.includes('-')
          ? obj.type
              .split('-')
              .map((val, index) =>
                index === 0 ? `${val.charAt(0).toUpperCase()}${val.slice(1)}` : val.toLowerCase()
              )
              .join(' ')
          : obj.type.charAt(0).toUpperCase() + obj.type.slice(1)
      };
    });
    filtered.length && setAuthors(filtered);
  }, [authorsList]);

  function handleAuthor({ key, value, index }) {
    const formatTypeString = value.toLowerCase().replace(' ', '-');
    handleAuthorUpdate({ key, value: formatTypeString, index });
  }

  function handleInput(payload) {
    handleAuthorUpdate(payload);
  }

  function handleAddDeleteAuthors(action) {
    handleAddDeleteAuthor(action);
  }

  return (
    <section id="authors" cy="course-authors" className="course-authors">
      <h2>Author information</h2>
      {authors.map(({ name, type, description, disclosure }, index) => (
        <div className="author-group" cy="author-group" key={index}>
          <h3>{`Author ${index + 1}`}</h3>
          <BaseTabsMenu
            key={type}
            name={`authorType${index + 1}`}
            label="Author type"
            value={type}
            options={options}
            onSelect={({ value }) => handleAuthor({ key: 'type', value, index })}
          />
          <BaseInput
            name={`authorName${index + 1}`}
            label="Author name"
            value={name}
            onInput={({ value }) => handleInput({ key: 'name', value, index })}
          />
          <BaseTextarea
            name={`authorDescription${index + 1}`}
            label={'Author description <span>(optional)</span>'}
            value={description}
            onInput={({ value }) => handleInput({ key: 'description', value, index })}
          />
          <BaseTextarea
            name={`authorDisclosure${index + 1}`}
            label={'Author disclosure <span>(optional)</span>'}
            value={disclosure}
            onInput={({ value }) => handleInput({ key: 'disclosure', value, index })}
          />
        </div>
      ))}
      <div className="flex flex-between">
        <BaseAddNewButton
          text="Add new author"
          cy="add-new-author"
          onClick={() => handleAddDeleteAuthors('add')}
        />
        <BaseDeleteButton
          text="Delete last author"
          onClick={() => handleAddDeleteAuthors('delete')}
        />
      </div>
    </section>
  );
};
